import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/',
    component: LayoutDefault,
    meta: {
      title: 'top_index',
      metaTags: {
        name: 'robots',
        content: 'noindex',
      },
    },
    children: [
      {
        path: '',
        name: 'TopIndex',
        meta: {
          title: 'top_index',
          description: 'meta.description.top_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/top/Index.vue'),
      },
      {
        path: '/notice',
        component: RouteWrapper,
        meta: {
          title: 'notice_index',
          description: 'meta.description.notice_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'NoticeIndex',
            meta: {
              title: 'notice_index',
              description: 'meta.description.notice_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/notice/Index.vue'),
          },
          {
            path: '/notice/create',
            name: 'NoticeCreate',
            meta: {
              title: 'notice_create',
              description: 'meta.description.notice_create',
              icon: 'edit',
            },
            component: () => import('@/views/notice/Create.vue'),
          },
          {
            path: '/notice/:notice_id',
            name: 'NoticeDetail',
            meta: {
              title: 'notice_detail',
              description: 'meta.description.notice_index',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/notice/Detail.vue'),
          },
        ],
      },
      {
        path: '/about',
        component: RouteWrapper,
        meta: {
          title: 'about_index',
          icon: 'about',
        },
        children: [
          {
            path: '',
            name: 'AboutIndex',
            meta: {
              title: 'about_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/about/Index.vue'),
          },
        ],
      },
      {
        path: '/faq',
        component: RouteWrapper,
        meta: {
          title: 'faq_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'FaqIndex',
            meta: {
              title: 'faq_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/faq/Index.vue'),
          },
        ],
      },
      {
        path: '/inquiry',
        component: RouteWrapper,
        meta: {
          title: 'inquiry_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'InquiryIndex',
            meta: {
              title: 'inquiry_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/inquiry/Index.vue'),
          },
        ],
      },
      {
        path: '/terms',
        component: RouteWrapper,
        meta: {
          title: 'terms_index',
          icon: 'terms',
        },
        children: [
          {
            path: '',
            name: 'TermsIndex',
            meta: {
              title: 'terms_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/terms/Index.vue'),
          },
        ],
      },
      {
        path: '/policy',
        component: RouteWrapper,
        meta: {
          title: 'policy_index',
          icon: 'policy',
        },
        children: [
          {
            path: '',
            name: 'PolicyIndex',
            meta: {
              title: 'policy_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/policy/Index.vue'),
          },
        ],
      },
      {
        path: '/service/:service_id',
        name: 'ServiceDetail',
        meta: {
          title: 'service_detail',
          mTitle: `meta.title.service_detail.:service_id`,
          description: `meta.description.service_detail.:service_id`,
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/service/Detail.vue'),
      },
      {
        path: '/onlineform',
        component: RouteWrapper,
        meta: {
          title: 'onlineform_index',
          description: 'meta.description.onlineform_index',
          icon: 'storefront',
        },
        children: [
          {
            path: '',
            name: 'OnlineformIndex',
            meta: {
              title: 'onlineform_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/onlineform/Index.vue'),
          },
          {
            path: '/onlineform/introduction/:onlineform_id',
            name: 'OnlineformIntroduction',
            meta: {
              title: 'onlineform_form',
              icon: 'pageview',
            },
            component: () => import('@/views/onlineform/Introduction.vue'),
          },
          {
            path: '/onlineform/form/:onlineform_id',
            name: 'OnlineformForm',
            meta: {
              title: 'onlineform_form',
              icon: 'pageview',
            },
            component: () => import('@/views/onlineform/Form.vue'),
          },
          {
            path: '/onlineform/form/:onlineform_id/:onlineform_application_id',
            name: 'OnlineformFormApplication',
            meta: {
              title: 'onlineform_form',
              icon: 'pageview',
            },
            component: () => import('@/views/onlineform/Form.vue'),
          },
          {
            path: '/onlineform/sign',
            name: 'OnlineformSign',
            meta: {
              title: 'onlineform_form',
              icon: 'list_alt',
            },
            component: () => import('@/views/onlineform/Sign.vue'),
          },
          {
            path: '/onlineform/error',
            name: 'OnlineformError',
            meta: {
              title: 'onlineform_error',
              icon: 'list_alt',
            },
            component: () => import('@/views/onlineform/Error.vue'),
          },
        ],
      },
      {
        path: '/spot',
        component: RouteWrapper,
        meta: {
          title: 'spot_index',
          description: 'meta.description.spot_index',
          icon: 'storefront',
        },
        children: [
          {
            path: '',
            name: 'SpotIndex',
            meta: {
              title: 'spot_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/spot/Index.vue'),
          },
          {
            path: '/spot/detail/:spot_id',
            name: 'SpotDetail',
            meta: {
              title: 'spot_detail',
              icon: 'pageview',
            },
            component: () => import('@/views/spot/Detail.vue'),
          },
          {
            path: '/spot/form/:spot_id/:room_id',
            name: 'SpotForm',
            meta: {
              title: 'spot_form',
              icon: 'pageview',
            },
            component: () => import('@/views/spot/Form.vue'),
          },
          {
            path: '/spot/form/:spot_id/:room_id/:reservation_id',
            name: 'SpotFormRepeat',
            meta: {
              title: 'spot_form',
              icon: 'pageview',
            },
            component: () => import('@/views/spot/Form.vue'),
          },
        ],
      },
      {
        path: '/spot/status',
        component: RouteWrapper,
        meta: { title: 'spot_status' },
        children: [
          {
            path: '',
            name: 'SpotStatus',
            meta: { title: 'spot_status' },
            component: () => import('@/views/spot/Status.vue'),
          },
        ],
      },
      {
        path: '/route/status',
        component: RouteWrapper,
        meta: { title: 'route_status' },
        children: [
          {
            path: '',
            name: 'RouteStatus',
            meta: { title: 'route_status' },
            component: () => import(/* webpackChunkName: "login" */ '@/views/route/Status.vue'),
          },
          {
            path: '/route/status/:route_id',
            name: 'RouteStatusDetail',
            meta: { title: 'route_status' },
            component: () => import(/* webpackChunkName: "login" */ '@/views/route/Status.vue'),
          },
        ],
      },
      {
        path: '/vehicle/status',
        component: RouteWrapper,
        meta: { title: 'vehicle_status' },
        children: [
          {
            path: '',
            name: 'VehicleStatus',
            meta: { title: 'vehicle_status' },
            component: () => import(/* webpackChunkName: "login" */ '@/views/vehicle/Status.vue'),
          },
        ],
      },
      {
        path: '/app',
        component: RouteWrapper,
        meta: {
          title: 'app_index',
          icon: 'smartphone',
        },
        children: [
          {
            path: '',
            name: 'AppIndex',
            meta: {
              title: 'app_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/app/Index.vue'),
          },
          {
            path: '/app/atinfocanal',
            name: 'AppAtinfocanal',
            meta: {
              title: 'app_atinfocanal',
              icon: 'pageview',
            },
            component: () => import('@/views/app/Atinfocanal.vue'),
          },
          {
            path: '/app/gomisuke',
            name: 'AppGomisuke',
            meta: {
              title: 'app_gomisuke',
              icon: 'pageview',
            },
            component: () => import('@/views/app/Gomisuke.vue'),
          },
          {
            path: '/app/machiiro',
            name: 'AppMachiiro',
            meta: {
              title: 'app_machiiro',
              icon: 'pageview',
            },
            component: () => import('@/views/app/Machiiro.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/login/index',
    component: LayoutAuth,
    meta: { title: 'login_index' },
    redirect: '/login',
    hidden: true,
    children: [
      {
        path: '/login',
        name: 'LoginIndex',
        meta: { title: 'login_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Index.vue'),
      },
    ],
  },

  {
    path: '/logout/index',
    component: LayoutAuth,
    meta: { title: 'logout_index' },
    redirect: '/logout',
    hidden: true,
    children: [
      {
        path: '/logout',
        name: 'LogoutIndex',
        meta: { title: 'logout_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/logout/Index.vue'),
      },
    ],
  },

  {
    path: '/signup/index',
    component: LayoutAuth,
    meta: { title: 'signup_index' },
    redirect: '/signup',
    hidden: true,
    children: [
      {
        path: '/signup',
        name: 'SignupIndex',
        meta: {
          title: 'signup_index',
          description: 'meta.description.signup_index',
        },
        component: () => import(/* webpackChunkName: "login" */ '@/views/signup/Index.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: '/password/reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/buslocation',
    component: RouteWrapper,
    meta: { title: 'buslocation_index' },
    redirect: '/buslocation/4',
    hidden: true,
    children: [
      {
        path: '/buslocation/:route_id',
        name: 'BuslocationIndex',
        meta: { title: 'buslocation_index' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/buslocation/Index.vue'),
      },
    ],
  },

  {
    path: '/spot/timetable',
    component: RouteWrapper,
    meta: { title: 'spot_timetable' },
    redirect: '/spot/timetable/44',
    hidden: true,
    children: [
      {
        path: '/spot/timetable/:spot_id',
        name: 'SpotTimetable',
        meta: { title: 'spot_timetable' },
        component: () => import('@/views/spot/Timetable.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/top',
    component: LayoutDefault,
    redirect: '/',
    meta: {
      title: 'top_index',
    },
    children: [
      {
        path: '/account',
        name: 'AccountIndex',
        meta: {
          title: 'account_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/account/Index.vue'),
      },
      {
        path: '/message',
        component: RouteWrapper,
        meta: {
          title: 'message_index',
          icon: 'campaign',
        },
        children: [
          {
            path: '',
            name: 'MessageIndex',
            meta: {
              title: 'message_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/message/Index.vue'),
          },
          {
            path: '/message/:message_id',
            name: 'MessageDetail',
            meta: {
              title: 'message_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/message/Detail.vue'),
          },
        ],
      },
      {
        path: '/member',
        component: RouteWrapper,
        meta: {
          title: 'member_index',
        },
        children: [
          {
            path: '',
            name: 'MemberIndex',
            meta: {
              title: 'member_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/member/Index.vue'),
          },
        ],
      },
      {
        path: '/schedule',
        component: RouteWrapper,
        meta: {
          title: 'schedule_index',
        },
        children: [
          {
            path: '',
            name: 'ScheduleIndex',
            meta: {
              title: 'schedule_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/schedule/Index.vue'),
          },
          {
            path: '/schedule/:member_id',
            name: 'ScheduleIndexOther',
            meta: {
              title: 'schedule_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/schedule/Index.vue'),
          },
          {
            path: '/schedule/detail/:schedule_id',
            name: 'ScheduleDetail',
            meta: {
              title: 'schedule_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/schedule/Detail.vue'),
          },
          {
            path: '/schedule/detail/:schedule_id/:target_date',
            name: 'ScheduleDetailTargeted',
            meta: {
              title: 'schedule_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/schedule/Detail.vue'),
          },
        ],
      },
      {
        path: '/reservation',
        component: RouteWrapper,
        meta: {
          title: 'reservation',
        },
        children: [
          {
            path: '',
            name: 'ReservationIndex',
            meta: {
              title: 'reservation_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/reservation/Index.vue'),
          },
          {
            path: '/reservation/history',
            name: 'ReservationHistory',
            meta: {
              title: 'reservation_history',
              icon: 'history',
            },
            component: () => import('@/views/reservation/History.vue'),
          },
          {
            path: '/reservation/calendar',
            name: 'ReservationCalendar',
            meta: {
              title: 'reservation_calendar',
              icon: 'event',
            },
            component: () => import('@/views/reservation/Calendar.vue'),
          },
          {
            path: '/reservation/detail/:reservation_id',
            name: 'ReservationDetail',
            meta: {
              title: 'reservation_detail',
              icon: 'pageview',
            },
            component: () => import('@/views/reservation/Detail.vue'),
          },
          {
            path: '/reservation/taxi/:reservation_id',
            name: 'ReservationTaxi',
            meta: {
              title: 'reservation_taxi',
              icon: 'local_taxi',
            },
            component: () => import('@/views/reservation/Taxi.vue'),
          },
        ],
      },
      {
        path: '/application',
        component: RouteWrapper,
        meta: {
          title: 'application',
        },
        children: [
          {
            path: '',
            name: 'ApplicationIndex',
            meta: {
              title: 'application_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/application/Index.vue'),
          },
          {
            path: '/application/detail/:onlineform_application_id',
            name: 'ApplicationDetail',
            meta: {
              title: 'application_detail',
              icon: 'pageview',
            },
            component: () => import('@/views/application/Detail.vue'),
          },
        ],
      },
      {
        path: '/withdrawal',
        name: 'WithdrawalIndex',
        meta: {
          title: 'withdrawal_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/withdrawal/Index.vue'),
      },
    ],
  },
]
